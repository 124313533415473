import React from 'react';
import styles from './HeroSection.module.css'; // Import the CSS module
import heroImage from '../../assets/images/hero-image.webp'; // Make sure the path matches where you've stored the image
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom'; 

const HeroSection = () => {
  return (
    <section className={styles.hero}>
      <div className={styles.heroImageWrapper}>
        <img src={heroImage} alt="Expert Tax Advisory" className={styles.heroImage} />
      </div>
      <div className={styles.heroContent}>
        <h1><FormattedMessage id="hero.welcome"/></h1>
        <p><FormattedMessage id="hero.slogan"/></p>
        <Link to="/contact"><button className={styles.cta}><FormattedMessage id="hero.started"/></button></Link>
      </div>
    </section>
  );
};

export default HeroSection;