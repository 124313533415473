import React from 'react';
import styles from './AboutUs.module.css'; // Make sure this path is accurate
import { FormattedMessage } from 'react-intl';

import teamImage from '../../assets/images/teamImage.webp';
import serviceImage from '../../assets/images/serviceImage.webp';

const AboutUs = () => {
  return (
    <div className={styles.page}>
      <section className={styles.header}>
        <h1><FormattedMessage id="hero.welcome"/></h1>
        <p><FormattedMessage id="about.slogan"/></p>
      </section>

      <section className={styles.missionSection}>
        <h2><FormattedMessage id="mission"/></h2>
        <p><FormattedMessage id="mission.text"/></p>
      </section>

      <div className={styles.flexContainer}>
        <section className={styles.teamSection}>
          <img src={teamImage} alt="Our Team" />
          <h2><FormattedMessage id="team"/></h2>
          <p><FormattedMessage id="team.text"/></p>
        </section>

        <section className={styles.servicesSection}>
          <img src={serviceImage} alt="Our Services" />
          <h2><FormattedMessage id="contact.services"/></h2>
          <p><FormattedMessage id="contact.services.text"/></p>
        </section>
      </div>

      <section className={styles.historyValuesSection}>
        <h2><FormattedMessage id="history"/></h2>
        <p><FormattedMessage id="history.text"/></p>
      </section>

      <footer className={styles.ctaFooter}>
        <h2><FormattedMessage id="get.in.touch"/></h2>
        <p><FormattedMessage id="get.in.touch.text"/></p>
      </footer>
    </div>
  );
};

export default AboutUs;
