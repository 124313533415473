import React, { useState, useEffect } from 'react';
import styles from './Header.module.css';
import logo from '../../assets/images/Origineel-resize-1.png';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const Header = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const location = useLocation(); // Get the current location

  // Collapse the navbar when the location changes
  useEffect(() => {
    setIsNavVisible(false);
  }, [location]);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <header className={styles.header}>
      <nav>
        <Link to="/" className={styles.logoLink}>
          <div className={styles.logo}>
            <img src={logo} alt="W.J. Belastingadvies Logo" />
          </div>
        </Link>
        <button className={styles.navToggle} aria-label="Toggle navigation" onClick={toggleNav}>
          <span className={styles.bar}></span>
          <span className={styles.bar}></span>
          <span className={styles.bar}></span>
        </button>
        <ul className={`${styles.navigation} ${isNavVisible ? styles.show : ''}`}>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about-us"><FormattedMessage id="about.us"/></Link></li>
          <li><Link to="/services"><FormattedMessage id="services"/></Link></li>
          <li><Link to="/resources"><FormattedMessage id="resources"/></Link></li>
          <li><Link to="/pricing"><FormattedMessage id="pricing"/></Link></li>
          <li><Link to="/contact"><FormattedMessage id="contact"/></Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
