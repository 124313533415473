import React from 'react';
import styles from './Pricing.module.css';
import { FormattedMessage } from 'react-intl';

const Pricing = () => {
  return (
    <div className={styles.pricingContainer}>
      <div className={styles.pricingHeader}>
        <h2><FormattedMessage id="pricing.affordable"/></h2>
        <p>
            <FormattedMessage id="pricing.affordable.text"/>
        </p>
      </div>
      {/* Container for the tiers */}
      <div className={styles.tiers}>
        <div className={styles.tier}>
          <header className={`${styles.header} ${styles.basic}`}><FormattedMessage id="pricing.single"/></header>
          <div className={styles.price}>€30</div>
          <ul className={styles.features}>
            <li><FormattedMessage id="pricing.individual"/></li>
            <li><FormattedMessage id="pricing.support"/></li>
            <li><FormattedMessage id="pricing.overview"/></li>
          </ul>
        </div>

        <div className={`${styles.tier} ${styles.premiumTier}`}> {/* Removed inline style for translateY */}
          <header className={`${styles.header} ${styles.premium}`}><FormattedMessage id="pricing.multi"/></header>
          <div className={styles.price}>€45</div>
          <ul className={styles.features}>
            <li><FormattedMessage id="pricing.two.individual"/></li>
            <li><FormattedMessage id="pricing.support"/></li>
            <li><FormattedMessage id="pricing.overview"/></li>
          </ul>
        </div>

        <div className={styles.tier}>
          <header className={`${styles.header} ${styles.executive}`}><FormattedMessage id="pricing.benefits"/></header>
          <div className={styles.price}>€25</div>
          <ul className={styles.features}>
            <li><FormattedMessage id="pricing.benefits.assistance"/></li>
            <li><FormattedMessage id="pricing.benefits.support"/></li>
            <li><FormattedMessage id="pricing.benefits.guidance"/></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pricing;

