import React from 'react';
import styles from './Resources.module.css'; // Adjust the import path as needed
import { FormattedMessage } from 'react-intl';

// Importing logos for resources and tools
import belastingdienstLogo from '../../assets/icons/belastingdienst.jpeg';
import kvkLogo from '../../assets/icons/kvk.png';
import toeslagenLogo from '../../assets/icons/toeslagen.png';
import mijnBelastingdienstLogo from '../../assets/icons/mijnbelastingdienst.png';
import taxCalculatorLogo from '../../assets/icons/calculator.png';
import vatCheckerLogo from '../../assets/icons/btw.png';

const Resources = () => {
  return (
    <div className={styles.resourcesPage}>
      <div className={styles.introSection}>
        <h1><FormattedMessage id="resources.welcome"/></h1>
        <p><FormattedMessage id="resources.welcome.text"/></p>
      </div>

      <div className={styles.resourcesList}>
        <h2><FormattedMessage id="resources.essentials"/></h2>
        <ul>
          <li>
            <img src={belastingdienstLogo} alt="Belastingdienst" className={styles.resourceLogo} />
            <a href="https://www.belastingdienst.nl" target="_blank" rel="noopener noreferrer"><FormattedMessage id="resources.belastingdienst"/></a> - <FormattedMessage id="resources.belastingdienst.text"/>
          </li>
          <li>
            <img src={kvkLogo} alt="Kamer van Koophandel" className={styles.resourceLogo} />
            <a href="https://www.kvk.nl" target="_blank" rel="noopener noreferrer"><FormattedMessage id="resources.kvk"/></a> - <FormattedMessage id="resources.kvk.text"/>
          </li>
          <li>
            <img src={toeslagenLogo} alt="Toeslagen" className={styles.resourceLogo} />
            <a href="https://www.toeslagen.nl" target="_blank" rel="noopener noreferrer"><FormattedMessage id="resources.benefits"/></a> - <FormattedMessage id="resources.benefits.text"/>
          </li>
          <li>
            <img src={mijnBelastingdienstLogo} alt="Mijn Belastingdienst" className={styles.resourceLogo} />
            <a href="https://mijn.belastingdienst.nl" target="_blank" rel="noopener noreferrer"><FormattedMessage id="resources.mijnbelastingdienst"/></a> - <FormattedMessage id="resources.mijnbelastingdienst.text"/>
          </li>
        </ul>
      </div>

      <div className={styles.toolsSection}>
        <h2><FormattedMessage id="resources.tools"/></h2>
        <ul>
          <li>
            <a href="https://www.belastingdienst.nl/rekenhulpen/toeslagen" target="_blank" rel="noopener noreferrer">
            <img src={taxCalculatorLogo} alt="Tax Calculator" className={styles.resourceLogo} />
            <strong><FormattedMessage id="resources.calculator"/></strong></a> <FormattedMessage id="resources.calculator.text"/>
          </li>
          <li>
            <a href="https://ec.europa.eu/taxation_customs/vies/" target="_blank" rel="noopener noreferrer">
            <img src={vatCheckerLogo} alt="VAT Checker" className={styles.resourceLogo} />
            <strong><FormattedMessage id="resources.vat"/></strong> </a><FormattedMessage id="resources.vat.text"/>
          </li>
        </ul>
      </div>

      <div className={styles.ctaSection}>
        <p><FormattedMessage id="resources.started"/></p>
        <p><FormattedMessage id="resources.started.text"/></p>
      </div>
    </div>
  );
};

export default Resources;
