import React, { useState } from 'react';
import styles from './Contact.module.css'; // Ensure the path is correct
import { FormattedMessage } from 'react-intl';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    date: '',
    message: '',
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Format the email body
    const emailBody = `
      Naam: ${formData.name}
      Gewenste datum afspraak: ${formData.date}
      Bericht: ${formData.message}
    `;
  
    // Encode the email body to ensure it's correctly formatted for a URL
    const encodedEmailBody = encodeURIComponent(emailBody);
  
    // Construct the mailto link with subject and encoded body
    const mailtoLink = `mailto:info@wjbelastingadvies.com?subject=Contactaanvraag van ${formData.name}&body=${encodedEmailBody}`;
  
    // Open the user's default email client with the pre-filled email
    window.location.href = mailtoLink;
  };

  return (
    <div className={styles.contactContainer}>
      {/* Introduction Section */}
      <section className={styles.contactIntro}>
        <h1><FormattedMessage id="get.in.touch"/></h1>
        <p><FormattedMessage id="contact.help"/></p>
      </section>

      {/* Contact Information */}
      <section className={styles.contactDetails}>
        <h2><FormattedMessage id="contact.today"/></h2>
        {/* <p><strong><FormattedMessage id="contact.open"/></strong> <FormattedMessage id="contact.open.hours"/></p> */}
        <p><strong><FormattedMessage id="email"/></strong> <FormattedMessage id="email.address"/></p>
        <p><strong><FormattedMessage id="phone"/></strong> +31 6 5585 4581</p>
        {/* <p><strong><FormattedMessage id="address"/></strong> <FormattedMessage id="address.text"/></p> */}
      </section>

      {/* Contact Form */}
      <section className={styles.formSection}>
        <h2><FormattedMessage id="send.us"/></h2>
        <form className={styles.contactForm} onSubmit={handleSubmit}>
          {/* Form fields with added onChange */}
          <div className={styles.formField}>
            <label htmlFor="name" className={styles.label}><FormattedMessage id="name"/></label>
            <input type="text" id="name" name="name" className={styles.input} required onChange={handleChange} />
          </div>
          {/* <div className={styles.formField}>
            <label htmlFor="email" className={styles.label}><FormattedMessage id="email"/></label>
            <input type="email" id="email" name="email" className={styles.input} required onChange={handleChange} />
          </div> */}
          <div className={styles.formField}>
            <label htmlFor="date" className={styles.label}><FormattedMessage id="date"/></label>
            <input type="date" id="date" name="date" className={styles.input} required onChange={handleChange} />
          </div>
          <div className={styles.formField}>
            <label htmlFor="message" className={styles.label}><FormattedMessage id="message"/></label>
            <textarea id="message" name="message" className={styles.textarea} required onChange={handleChange}></textarea>
          </div>
          <button type="submit" className={styles.submitButton}><FormattedMessage id="send"/></button>
        </form>
      </section>

      {/* Stay Connected Section */}
      <section className={styles.stayConnected}>
        <p><FormattedMessage id="contact.connected"/></p>
        <p><FormattedMessage id="contact.follow"/></p>
        {/* Insert social media icons or links here */}
      </section>
    </div>
  );
};

export default Contact;
