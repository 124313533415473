import { createIntl, createIntlCache } from 'react-intl';
// Locale data
import en from './locales/en.json';
import nl from './locales/nl.json';
import de from './locales/de.json';

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

const messages = {
  en, // English messages
  nl, // Dutch messages
  de // Still English
};

const locale = navigator.language.split(/[-_]/)[0];  // Language without region code

const intl = createIntl({
  locale,
  messages: messages[locale],
}, cache);

export default intl;