import React from 'react';
import ServicesSection from './ServicesSection'; // Make sure this path is correct
import styles from './ServicePage.module.css'; // Make sure this path is correct
import { FormattedMessage } from 'react-intl';

const ServicesPage = () => {
  return (
    <div className={styles.servicesPage}>
      <div className={styles.introSection}>
        <h1><FormattedMessage id="service.welcome"/></h1>
        <p><FormattedMessage id="service.welcome.text"/></p>
      </div>
      
      {/* Removed services prop as it's not defined in this scope */}
      <ServicesSection />

      <div className={styles.ctaSection}>
        <p><FormattedMessage id="service.started"/></p>
        <p><FormattedMessage id="service.started.text"/></p>
      </div>
    </div>
  );
};

export default ServicesPage;
