import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import HeroSection from './components/Hero/HeroSection';
import ServicePage from './components/Services/ServicePage';
import AboutUs from './components/AboutUs/AboutUs';
import Resources from './components/Resources/Resources';
import Pricing from './components/Pricing/Pricing';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import HomePageContent from './components/HomePage/HomePageContent';
import './App.css';
import intl from './i18n'; // Import the intl object you created
import { IntlProvider } from 'react-intl';

function App() { 
  return (
    <IntlProvider locale={intl.locale} messages={intl.messages}>
      <Router>
        <div className="App">
          <Header />
          <main>
            <Routes>
            <Route path="/" element={
              <main>
                <HeroSection />
                <HomePageContent />
                {/* Include any other components you want on the homepage */}
              </main>
            } />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/services" element={<ServicePage />} />
              <Route path="/resources" element={<Resources />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/contact" element={<Contact />} />
              {/* Define more routes as needed */}
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
      </IntlProvider>
  );
}

export default App;
