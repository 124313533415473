import React from 'react';
import styles from './HomePageContent.module.css'; // Ensure this is the correct path
import aboutUsIcon from '../../assets/images/aboutus.webp';
import servicesIcon from '../../assets/images/services.webp';
import resourcesIcon from '../../assets/images/resources.webp';
import contactIcon from '../../assets/images/contact.webp';
import { Link } from 'react-router-dom'; // Add this line
import { FormattedMessage } from 'react-intl';



const HomePageContent = () => {
  return (
    <div className={styles.homeContent}>
      <Link to="/about-us" className={styles.iconContainer}>
        <img src={aboutUsIcon} alt="About Us" className={styles.icon} />
        <p><FormattedMessage id="about.us"/></p>
        <span className={styles.descriptionText}><FormattedMessage id="about.us.text"/></span>
      </Link>
      <Link to="/services" className={styles.iconContainer}>
        <img src={servicesIcon} alt="Services" className={styles.icon} />
        <p><FormattedMessage id="services"/></p>
        <span className={styles.descriptionText}><FormattedMessage id="services.text"/></span>
      </Link>
      <Link to="/resources" className={styles.iconContainer}>
        <img src={resourcesIcon} alt="Resources" className={styles.icon} />
        <p><FormattedMessage id="resources"/></p>
        <span className={styles.descriptionText}><FormattedMessage id="resources.text"/></span>
      </Link>
      <Link to="/contact" className={styles.iconContainer}>
        <img src={contactIcon} alt="Contact" className={styles.icon} />
        <p><FormattedMessage id="contact"/></p>
        <span className={styles.descriptionText}><FormattedMessage id="contact.text"/></span>
      </Link>
    </div>
  );
};

export default HomePageContent;
