import React from 'react';
import styles from './ServicesSection.module.css'; // Import the CSS module
import taxPlanningIcon from '../../assets/icons/taxplanningservice.webp';
import complianceIcon from '../../assets/icons/complianceservice.webp';
import businessAdvisoryIcon from '../../assets/icons/businessservice.webp';
import estatePlanningIcon from '../../assets/icons/estateservice.webp';
import { FormattedMessage } from 'react-intl';

const ServiceCard = ({ title, description, icon }) => {
  return (
    <div className={styles.serviceCard}>
      <img src={icon} alt={`${title} icon`} />
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

const services = [
  {
    id: 1,
    title: <FormattedMessage id="service.planning"/>,
    description: <FormattedMessage id="service.planning.text"/>,
    icon: taxPlanningIcon,
  },
  {
    id: 2,
    title: <FormattedMessage id="service.compliance"/>,
    description: <FormattedMessage id="service.compliance.text"/>,
    icon: complianceIcon,
  },
  {
    id: 3,
    title: <FormattedMessage id="service.business"/>,
    description: <FormattedMessage id="service.business.text"/>,
    icon: businessAdvisoryIcon,
  },
  {
    id: 4,
    title: <FormattedMessage id="service.estate"/>,
    description: <FormattedMessage id="service.estate.text"/>,
    icon: estatePlanningIcon,
  },
  // Additional services as required
];

const ServicesSection = () => {
  return (
    <section className={styles.services}>
      {services.map(service => (
        <ServiceCard key={service.id} {...service} />
      ))}
    </section>
  );
};

export default ServicesSection;
