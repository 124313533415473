import React from 'react';
import styles from './Footer.module.css';
import { FormattedMessage } from 'react-intl';

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Dynamically get the current year

  return (
    <footer className={styles.footer}>
      <div className={styles.contactInfo}>
        <a href="mailto:info@wjbelastingadvies.nl">info@wjbelastingadvies.nl</a>
      </div>
      <div className={styles.quickLinks}>
        {/* Quick links can be added here in the future */}
      </div>
      <div className={styles.legal}>
        © {currentYear} wjbelastingadvies.nl. <FormattedMessage id="copyright"/>
      </div>
    </footer>
  );
};

export default Footer;
